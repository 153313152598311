<template>
  <el-dialog
    :visible.sync="showDialog"
    title="查看关联设备"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="970px"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-divider></el-divider>
      <el-row class="button-group">
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-remove"
          @click="handleRemoveAll"
          >解绑所有设备</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-remove"
          @click="handleRemoveSelect"
          >解绑选择的设备</el-button
        >
        
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-refresh"
          @click="handleUpdateIsWrite"
          >更新图片状态</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-refresh"
          :disabled="multipleSelection.length == 0"
          @click="dataSync"
          >数据同步</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          @click="handleReLoad"
          >刷新</el-button
        >
      </el-row>
      <el-table
        :data="tableData"
        style="min-height: 400px"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="deviceInfo.aliasName"
          label="设备名称"
          width="220"
        ></el-table-column>
        <el-table-column
          prop="personInfo.name"
          label="人员名称"
          width="120"
        ></el-table-column>
        <el-table-column prop="deviceInfo.isOnline" label="运行状态" width="80">
          <template slot-scope="{ row }">
            <div
              v-if="row.deviceInfo.isOnline"
              style="
                border-radius: 30px;
                background-color: #67c23a;
                width: 20px;
                height: 20px;
              "
            ></div>
            <div
              v-if="!row.deviceInfo.isOnline"
              style="
                border-radius: 30px;
                background-color: #f56c6c;
                width: 20px;
                height: 20px;
              "
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="isBound" label="照片是否存在" width="120">
          <template slot-scope="{ row }">
            <div
              v-if="!row.deviceInfo.isOnline"
              style="
                border-radius: 30px;
                background-color: #767676;
                width: 20px;
                height: 20px;
              "
            ></div>
            <div
              v-if="row.deviceInfo.isOnline && row.isBound"
              style="
                border-radius: 30px;
                background-color: #67c23a;
                width: 20px;
                height: 20px;
              "
            ></div>
            <div
              v-if="row.deviceInfo.isOnline && !row.isBound"
              style="
                border-radius: 30px;
                background-color: #f56c6c;
                width: 20px;
                height: 20px;
              "
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="remoteOpen" label="远程开门" width="80">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.remoteOpen"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeRemote(row)"
              :disabled="row.isDefault"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="关联时间"
          width="150"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button size="mini" type="danger" @click="handleDelete(row)"
              >解除关联</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <personDeviceRelation-detail
        v-if="showModal"
        :businessKey="businessKey"
        :title="modalTitle"
        @close="onDetailModalClose"
      ></personDeviceRelation-detail>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import PersonDeviceRelationDetail from "./personDeviceRelation-detail";
import personDeviceRelationApi from "@/api/base/personDeviceRelation";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["personId"],
  data() {
    var self = this;

    return {
      queryModel: {
        deviceId: "",
        personId: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("deviceId", self.queryModel.deviceId);
      formData.append("personId", self.personId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personDeviceRelationApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;
          console.log(jsonData.data);
          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleDelete(record) {
      var self = this;
      this.$confirm("是否解除关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          self.loading = true;

          personDeviceRelationApi.remove(record.id).then(function (response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "解绑成功!",
              });
            }
          });
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    handleRemoveSelect() {
      var self = this;

      var deviceIdList = this.multipleSelection.map((record) => {
        return record.deviceId;
      });

      this.$confirm("是否解除关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          self.loading = true;
          var formData = new FormData();
          formData.append("personId", self.personId);
          formData.append("deviceIds", deviceIdList);

          personDeviceRelationApi
            .unbindSelectDevice(formData)
            .then(function (response) {
              var jsonData = response.data;
              self.loading = false;
              if (jsonData.result) {
                self.changePage(self.pageIndex);

                self.$message({
                  type: "success",
                  message: "解绑成功!",
                });
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    handleRemoveAll() {
      var self = this;

      this.$confirm("是否解除关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          self.loading = true;
          var formData = new FormData();
          formData.append("personId", self.personId);

          personDeviceRelationApi
            .unbindDevice(formData)
            .then(function (response) {
              var jsonData = response.data;
              self.loading = false;
              if (jsonData.result) {
                self.changePage(self.pageIndex);

                self.$message({
                  type: "success",
                  message: "解绑成功!",
                });
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleUpdateIsWrite() {
      var self = this;
      self.loading = true;
      var formData = new FormData();
      formData.append("personId", self.personId);
      personDeviceRelationApi
        .updatePersonIsWrite(formData)
        .then(function (response) {
          var jsonData = response.data;
          self.loading = false;
          if (jsonData.result) {
            self.changePage(self.pageIndex);
            self.$message({
              type: "success",
              message: "更新成功!",
            });
          }
        });
    },
    dataSync() {
      //批量同步人脸
      var self = this;
      var deviceIdList = this.multipleSelection.map((record) => {
        return record.deviceId;
      });

      this.$confirm("是否确认同步选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          self.loading = true;
          var formData = new FormData();
          formData.append("personId", self.personId);
          formData.append("deviceIds", deviceIdList);

          personDeviceRelationApi
            .devicesPersonSync(formData)
            .then(function (response) {
              var jsonData = response.data;
              self.loading = false;
              if (jsonData.result) {
                if (jsonData.data) {
                  self.changePage(self.pageIndex);
                  self.$message({
                    type: "success",
                    message: "同步成功!",
                  });
                } else {
                  if (jsonData.message != null) {
                    //下载有错误信息提示的报表
                    //window.open(response.data);
                    self.$message({
                      showClose: true,
                      dangerouslyUseHTMLString: true,
                      message:
                        "错误" +
                        `,<a href="${jsonData.message}" target="_blank">点击下载错误报表</a>&nbsp;`,
                      duration: 30000,
                    });
                  }
                }
              } else {
                self.$message({
                  type: "warning",
                  message: jsonData.message,
                });
              }
            });
        })
        .catch(() => {
          self.loading = false;
        });
    },
    changeRemote(row) {
      personDeviceRelationApi.update(row);
    },
    handleReLoad() {
      var self = this;
      self.changePage(self.pageIndex);
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "personDeviceRelation-detail": PersonDeviceRelationDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>