import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/companyPosition/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/companyPosition/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/companyPosition/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/companyPosition/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/companyPosition/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/companyPosition/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/companyPosition/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function detailForCompany() {
  return request.get(constant.serverUrl + "/base/companyPosition/detailForCompany");
}

export default {
  create, edit, add, update, remove, batchRemove, pageList, detailForCompany
}